@font-face {
font-family: 'montserrat';
src: url(/_next/static/media/38d5f5a3b13554c3-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: 'montserrat';
src: url(/_next/static/media/3fe2265df2ccd31b-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: 'montserrat';
src: url(/_next/static/media/bd99f84e4550e64f-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: 'montserrat';
src: url(/_next/static/media/7f3ca5d3a82d52f7-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: 'montserrat';
src: url(/_next/static/media/6451693567500a3e-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: 'montserrat Fallback';src: local("Arial");ascent-override: 84.95%;descent-override: 22.03%;line-gap-override: 0.00%;size-adjust: 113.95%
}.__className_3f0df2 {font-family: 'montserrat', 'montserrat Fallback'
}

